<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Funcionário"
            name="funcionario"
            placeholder="Funcionário"
            v-model="funcionario"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data saída atual"
            name="dataSaida"
            placeholder="Data saída atual"
            v-model="dataSaidaRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-menu
            class="pr-2"
            ref="bornDate"
            lazy
            :close-on-content-click="false"
            v-model="dataSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="novaDataSaidaRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Nova data de saída"
              v-model="novaDataSaidaRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="novaDataSaidaRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.bornDate.save(novaDataSaidaRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            class="mr-2"
            label="Data retorno atual"
            name="dataRetorno"
            placeholder="Data retorno atual"
            v-model="dataRetornoRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-menu
            class="pr-2"
            ref="retornoDate"
            lazy
            :close-on-content-click="false"
            v-model="dataRetorno"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="novaDataRetornoRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Nova data de retorno"
              v-model="novaDataRetornoRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="novaDataRetornoRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataRetorno = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.retornoDate.save(novaDataRetornoRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-switch v-model="switch1" :label="`Status: ${switch1 == true ? 'FINALIZADO' : 'INICIADO'}`"></v-switch>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="form-btn">
          <v-btn @click="voltar" outline>Voltar</v-btn>
          <v-btn outline @click="salvar()" color="primary">Salvar</v-btn>
        </div>
        <div>
          <progress-circular></progress-circular>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import ProgressCircular from "../progressCircular/progressCircular"
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
const servicoRomaneio = new ServicoRomaneio()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      id: null,
      valid: false,
      idFuncionario: null,
      funcionario: "",
      praca: "",
      idPraca: null,
      dataSaida: false,
      novaDataSaidaRomaneio: null,
      dataRetorno: false,
      novaDataRetornoRomaneio: null,
      switch1: true,
      dataRetornoRomaneio: null,
      dataSaidaRomaneio: null
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoRomaneio.buscarPorId(this.id).then(res => {
        this.view = true
        this.id = res.data.id
        this.idFuncionario = res.data.idFuncionario
        this.funcionario = res.data.funcionario
        this.praca = res.data.praca
        this.idPraca = res.data.idPraca
        this.dataSaidaRomaneio = res.data.dataSaidaString
        this.dataRetornoRomaneio = res.data.dataRetornoString
        this.switch1 = res.data.finalizado
      })
    }
  },
  methods: {
    imprimir(id) {
      servicoRomaneio.imprimir(id)
    },
    voltar() {
      this.$router.push({ name: "romaneio" })
    },
    salvar() {
      this.abrirProgressCircular()
      
      var dtSaidaArray = this.dataSaidaRomaneio.split("/")
      var dtRetornoArray = this.dataRetornoRomaneio.split("/")
      this.dataSaidaRomaneio = new Date(dtSaidaArray[2], dtSaidaArray[1] - 1, dtSaidaArray[0])
      this.dataRetornoRomaneio = new Date(dtRetornoArray[2], dtRetornoArray[1] - 1, dtRetornoArray[0])
      servicoRomaneio
        .salvarDatas(
          this.id,
          this.novaDataSaidaRomaneio,
          this.dataSaidaRomaneio,
          this.novaDataRetornoRomaneio,
          this.dataRetornoRomaneio,
          this.switch1
        )
        .then(res => {
          if (res.status === 200) {
            this.fecharProgressCircular()
            this.voltar()
          }
        })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
